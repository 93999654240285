import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import settings from '../../global/settings';

import Button from '../atoms/AllButtonAlt';
import ShopNowButtons from 'components/CustomLanding/GoodHairMagic/shopNowButtons';
export const SliderBestSellerItemWrapper = styled.div`
    width: auto;

    &:first-child {
        margin-left: 51px;
    }
    @media (max-width: ${settings.bp.medium.view}) {
        flex-shrink: 1 !important;
    }
`;

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;

    ${props =>
        !props.removeWidth &&
        css`
            width: 152px;
        `};

    padding-top: 20px;
    padding-bottom: 24px;

    @media (min-width: ${settings.bp.medium.view}) {
        width: auto;
        min-width: 250px;

        padding-left: 0px;
        padding-right: 0px;

        padding-top: 50px;
        padding-bottom: 24px;
    }
    @media (min-width: ${settings.bp.large.view}) {
        min-width: 300px;
    }
    @media (min-width: 2500px) {
        img:not(.rating) {
            width: 100%;
        }
    }

    ${props =>
        props.increasePadding &&
        !props.isPro &&
        css`
            padding-bottom: 125px;
        `};
    ${props =>
        props.increasePadding &&
        props.isPro &&
        css`
            padding-bottom: 175px;
        `};
`;

const ItemImageWrapper = styled.div`
    padding-left: 9px;
    padding-right: 9px;
    position: relative;
    min-height: 134px;
    @media (min-width: ${settings.bp.medium.view}) {
        padding-left: 0px;
        padding-right: 0px;
    }

    object-fit: contain;
    & img:nth-child(1) {
        // display: block;
    }
    & img:nth-child(2) {
        display: none;
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
        left: 0;
        top: 0;
    }
    &:hover {
        & img:nth-child(1) {
            // display: none;
        }
        & img:nth-child(2) {
            display: block;
        }
    }
    ${props =>
        props.removeWidth &&
        css`
            padding-left: 0px;
            padding-right: 0px;
        `};
`;

const ItemName = styled.div`
    font-weight: 500;
    text-align: center;
    letter-spacing: 0.08em;
    text-transform: uppercase;
    font-size: 12.175px;
    font-style: normal;
    line-height: 17.586px;
    letter-spacing: 0.773px;
    padding-top: 16px;
    min-height: 58px;

    @media (min-width: ${settings.bp.medium.view}) {
        font-size: 16px;
        padding-top: 24px;
        min-height: unset;
        line-height: 26px;
        letter-spacing: 1.143px;
        color: #121212;
    }

    ${props =>
        props.variant == 5
            ? css`
                  text-transform: unset;
                  font-size: 18px !important;
                  letter-spacing: 2px;
                  padding-left: 24px;
                  padding-right: 24px;
              `
            : null};
`;

const ItemDescription = styled.div`
    font-size: 12px;
    color: #656565;
    text-align: center;

    flex-grow: 1;
    min-height: 44px;
    padding-top: 8px;
    @media (min-width: ${settings.bp.medium.view}) {
        padding-top: 10px;
        min-height: unset;
    }
`;

const ItemRating = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 8px;
`;

const ItemShopNow = styled.div`
    padding-top: 8px;
    @media (min-width: ${settings.bp.medium.view}) {
        padding-top: 16px;
    }
`;

const StyledButton = styled(Button)`
    margin: 0;
`;

const StarWrapper = styled.img`
    width: 70px;
    @media (min-width: ${settings.bp.medium.view}) {
        width: 110px;
    }
`;

const GifImage = styled.img`
    width: 100%;
    height: 100%;
    display: block;
`;

const GifDesktopImage = styled.img`
    width: 100%;
    height: 100%;
    display: block;

    @media (max-width: ${settings.bp.medium.view}) {
        display: none;
    }
`;

const GifMobileImage = styled.img`
    width: 100%;
    height: 100%;
    display: none;
    @media (max-width: ${settings.bp.medium.view}) {
        display: block;
    }
`;

const SliderBestSellerItem = ({
    title,
    description,
    url,
    gifOverlayImage,
    homepageImage,
    productId,
    shopButtonStyle,
    starsImage,
    removeWidth,
    externalShopNow,
    isPro,
    increasePadding,
    autoRotate,
    shopButtonText,
    titleStyle,
}) => {
    const buttonVariant = shopButtonStyle || '2';
    const [index, setIndex] = useState(0);
    useEffect(() => {
        if (autoRotate) {
            const interval = setInterval(() => {
                setIndex(index === 1 ? 0 : 1);
            }, 3000);
            return () => clearInterval(interval);
        }
    }, [index, autoRotate]);
    return (
        <Wrapper
            isPro={isPro}
            increasePadding={increasePadding}
            removeWidth={removeWidth}
        >
            <ItemImageWrapper removeWidth={removeWidth}>
                {homepageImage && !autoRotate && (
                    <GifImage src={homepageImage} />
                )}
                {homepageImage && autoRotate && (
                    <GifDesktopImage src={homepageImage} />
                )}
                {gifOverlayImage && <GifImage src={gifOverlayImage} />}
                {homepageImage && autoRotate && (
                    <GifMobileImage
                        src={
                            index === 0
                                ? homepageImage
                                : gifOverlayImage || homepageImage
                        }
                    />
                )}
            </ItemImageWrapper>
            <ItemName variant={buttonVariant}>{title}</ItemName>
            {description && <ItemDescription>{description}</ItemDescription>}
            {starsImage ? (
                starsImage
            ) : (
                <ItemRating>
                    <div
                        data-bv-show="inline_rating"
                        data-bv-product-id={productId}
                    ></div>
                </ItemRating>
            )}
            {externalShopNow ? (
                <ShopNowButtons productId={productId} isPro={isPro} />
            ) : (
                <ItemShopNow>
                    <StyledButton variant={buttonVariant} href={url}>
                        {shopButtonText || `Shop now`}
                    </StyledButton>
                </ItemShopNow>
            )}
        </Wrapper>
    );
};

export default SliderBestSellerItem;
